import React from "react"
import PropTypes from 'prop-types';
import {  withStyles } from "@material-ui/core"
import Layout from '../components/layout';
import Helmet from 'react-helmet';

const styles = theme => ({
});

function Content(props) {
  return (
    <Layout>
      <Helmet>
          <title>Page not found - QHFS Limited</title>
      </Helmet>
      <h2>Page not found</h2>

      <p>Sorry we could not find that page.</p>
    </Layout>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);